import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import addEditDashboardAccessRight from './addEditDashboardAccessRight';
import appStrings from '../../../utility/string.utility';

export default {
  name: 'dashboardAccessRight',
  components: {
    DatePicker,
    addEditDashboardAccessRight
  },
  watch: {
    currentPage: function() {
      this.getAdminDashboardData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAdminDashboardData();
    }
  },

  data() {
    return {
      loader: false,
      user: {
        value: null,
        text: null
      },
      dashboardName: {
        value: null,
        text: null
      },
      isGlobalAccess: true,
      startDate: null,
      endDate: null,
      vsetCode: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      addDashboardAccessRight: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      adminUserId: null,
      dashboardData: {},

      dashboardAccessRightData: [],
      dashboardAccessRightFields: [
        {
          key: 'user_id'
        },
        {
          key:'user_name'
        },
        {
          key: 'dashboard_name',
          label: 'Dashboard '
        },
        {
          key: 'is_global_access'
        },
        {
          key: 'start_date',
          label: 'Start Date'
        },
        {
          key: 'end_date',
          label: 'End Date'
        },
        {
          key: 'last_updated_by',
          label: 'Last Updated By'
        },
        {
          key: 'created_by',
          label: 'Created By'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.addDashboardAccessRight = true;
          this.adminUserId = null;
        }
      }
    });
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GET_USER_NAME_BY_USER_ID) {
        this.user.text = item.user_name;
        this.user.value = item.user_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DASHBOARD_VSET) {
        this.dashboardName.text = item.dashboard_name;
        this.dashboardName.value = item.admin_dashboard_id;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.GET_USER_NAME_BY_USER_ID) {
        this.user = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.DASHBOARD_VSET) {
        this.dashboardName = {
          value: null,
          text: null
        };
      }
    },
    getAdminDashboardData() {
      if (this.dashboardName.text === null) {
        alert('Please select mandatory fields');
      } else {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          user_id: this.user.value,
          dashboard_id: this.dashboardName.value,
          start_date: this.startDate,
          end_date: this.endDate,
          is_global: this.isGlobalAccess 
        };
        this.loader = true;
        this.$store
          .dispatch('dashboardAccessRight/getAdminGridDashboardData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.dashboardAccessRightData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showhideDashboardAccessRightModal(flag) {
      this.addDashboardAccessRight = flag;
      this.getAdminDashboardData();
    },
    clear() {
      this.user = {
        value: null,
        text: null
      };
      (this.dashboardName = {
        value: null,
        text: null
      }),
        (this.startDate = null);
      this.endDate = null;
      this.isGlobalAccess = null;
      this.dashboardAccessRightData = [];
    },
    rowSelected(item) {
      this.adminUserId = item.admin_user_dashboard_uax_id;
      this.addDashboardAccessRight = true;
      this.dashboardData=item;
      this.getAdminDashboardData();
      this.editMode=false;
    },
    
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
