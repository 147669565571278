import DatePicker from 'vue2-datepicker';
import appStrings from '../../../../utility/string.utility';

export default {
  name: 'addEditDashboardAccessRight',
  props: ['dashboardData', 'adminUserId'],
  components: {
    DatePicker
  },
  data() {
    return {
      loader: false,
      user: {
        text: null,
        value: null
      },
      dashboardName: {
        text: null,
        value: null
      },
      isGlobalAccess: false,
      startDate: null,
      endDate: null,
      showValueSetModal: false,
      showAlert: false,
      isSuccess: false,
      respMessage: null,
      editMode: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.postAdminDashboardData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (this.adminUserId) {
      this.getAdminGridDashboardDataById(this.adminUserId);
    } else {
      this.editMode = true;
    }
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GET_USER_NAME_BY_USER_ID) {
        this.user.text = item.user_name;
        this.user.value = item.user_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DASHBOARD_VSET) {
        this.dashboardName.text = item.dashboard_name;
        this.dashboardName.value = item.admin_dashboard_id;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.GET_USER_NAME_BY_USER_ID) {
        this.user = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.DASHBOARD_VSET) {
        this.dashboardName = {
          value: null,
          text: null
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    postAdminDashboardData() {
      if (
        this.user.text === null ||
        this.dashboardName.text === null ||
        this.startDate === null
      ) {
        alert('Please select mandatory fields');
      } else {
        const payload = {
          admin_user_dashboard_uax_id: this.adminUserId ? this.adminUserId : 0,
          user_id: this.user.value,
          dashboard_id: this.dashboardName.value,
          is_global_access: this.isGlobalAccess,
          start_date: this.startDate,
          end_date: this.endDate
        };
        this.loading = true;
        this.$store
          .dispatch('dashboardAccessRight/postAdminDashboardData', payload)
          .then(response => {
            if (response.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getAdminGridDashboardDataById(adminUserId) {
      let payload = {
        uax: adminUserId
      };
      this.loading = true;
      this.$store
        .dispatch('dashboardAccessRight/getAdminGridDashboardDataById', payload)
        .then(response => {
          if (response.status === 200) {
            this.user.text = response.data.data.user_name;
            this.user.value = response.data.data.user_id;
            this.dashboardName.text = response.data.data.dashboard_name;
            this.startDate = response.data.data.start_date;
            this.endDate = response.data.data.end_date;
            this.isGlobalAccess = response.data.data.is_global_access;
            this.dashboardName.value = response.data.data.dashboard_id;
            this.adminUserId = response.data.data.admin_user_dashboard_uax_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
